import React from 'react';
import 'twin.macro';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '~components/Layout';
import SEO from '~components/SEO';
import Hero from '~components/Hero';
import Heading, { StyledHeading } from '~components/Heading';
import { IconCard } from '~components/Card';
import ImageList, { imageListStyle } from '~components/ImageList';
import Text from '~components/Text';

import Access from '~components/Svg/Access';
import Bag from '~components/Svg/Bag';
import Check from '~components/Svg/Check';
import Directions from '~components/Svg/Directions';
import Globe from '~components/Svg/Globe';
import Pointer from '~components/Svg/Pointer';

import { VARIANT } from '../../enums';

const ClimatePage: React.FC = () => (
  <Layout>
    <SEO title="Climate Change" desc="" pathname="/aspirations/climate-change" />
    <Hero title="Make a measurable impact on climate change">
      <StaticImage src="../../images/students-looking-up-atsolar-panels.jpg" alt="" layout="fullWidth" />
    </Hero>
    <div tw="container mb-24">
      <div tw="max-w-5xl">
        <Text>
          Climate change continues to be the biggest challenge of our lifetime. Our decisions and
          actions impact future generations. We will continue to focus on climate action to protect
          our environment. It is a global priority.
        </Text>
      </div>
    </div>
    <ImageList
      title="Strategic Direction"
      items={[
        'Use the Mohawk College Climate Action Plan and the United Nations Sustainable Development Goals (UNSDGs) as our guiding frameworks to set and achieve targets to ensure that all our work and business processes include a sustainability and climate change lens.',
        'Embed sustainability and climate change curriculum, applied research and capstone projects into programs that will prepare a low-carbon workforce and a transition to a low-carbon economy.',
        'Leverage our networks and partners, both locally and nationally, to accelerate our climate action initiatives and lead the transition to a clean economy stemming from the work developed through the Canadian Colleges for a Resilient Recovery.',
        'Develop a strategy to integrate Indigenous learning and practices in sustainability and climate change initiatives.'
      ]}
    >
      <StaticImage
        src="../../images/joyce-centre.jpg"
        alt=""
        layout="fullWidth"
        style={imageListStyle}
      />
    </ImageList>
    <div tw="mb-24 bg-orange text-gray-800 py-12">
      <div tw="container">
        <div tw="justify-between space-x-6 items-center md:(flex)">
          <Globe tw="h-32 w-32 flex-shrink-0 md:(order-2 h-48 w-48)" />
          <div tw="md:(order-1)">
            <Heading level="h2" tw="mt-0">
              Leadership Outcome
            </Heading>
            <Text tw="text-2xl font-bold">
              We will be the leader in climate action and sustainability in Canada and beyond,
              recognized for our exceptional climate action education and research and for the bold
              targets we continue to set for the college.
            </Text>
          </div>
        </div>
      </div>
    </div>
    <div tw="container">
      <div tw="max-w-5xl mb-12">
        <Heading level="h2" tw="text-rose">
          Building on Momentum
        </Heading>
        <Text tw="text-xl font-bold">
          We have taken significant action to reduce greenhouse gas, and to inspire our students to
          be the sustainability leaders of tomorrow through curriculum and research, campus and
          public engagement, purchasing, transportation, energy, buildings and waste.
        </Text>
        {/* <Heading level="h3">1. Mohawk first</Heading>
        <Text>
          Mohawk’s Environmental Management Plan is the first of its kind for an Ontario college.
        </Text>
        <Heading level="h3">2. Mohawk best</Heading>
        <Text>
          Mohawk has joined eight educational institutions across Canada to build a post-pandemic
          workforce that supports a climate-focussed economic recovery in an initiative called
          Canadian Colleges for a Resilient Recovery. The group will champion projects across Canada
          to support a recovery that delivers good jobs, is positive for the environment, and
          addresses socio-economic inequality.
        </Text>
        <Heading level="h3">3. Mohawk best</Heading>
        <Text>
          The Bay Area Climate Change Office, a collaboration between the Cities of Hamilton and
          Burlington and Mohawk’s Centre for Climate Change Management, demonstrates how colleges
          can support their region’s transition to a low-carbon economy by partnering with
          municipal, industry and community partners on climate change interventions.
        </Text>
        <Heading level="h3">4. Mohawk only</Heading>
        <Text>
          Our Climate Change Leaders program will inspire the sustainability leaders of tomorrow by
          bringing climate change curriculum and awareness of low-carbon alternatives into
          elementary and secondary classrooms to teach students about sustainability beginning at a
          young age.
        </Text> */}
      </div>
      <StyledHeading>Aspirations</StyledHeading>
      <div tw="grid gap-10 my-12 md:(grid-cols-2) lg:(grid-cols-3)">
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/truth-reconciliation"
          Icon={<Directions tw="w-12" />}
        >
          Meaningfully advance Truth and Reconciliation
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/future-ready" Icon={<Check tw="w-12" />}>
          Enable Future Ready education, research, programs, services and experiences – for
          students, employees and our community
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/celebrate-every-person"
          Icon={<Access tw="w-12" />}
        >
          Be a place that honours, values and celebrates the whole of every person
        </IconCard>
        <IconCard variant={VARIANT.RED} to="/aspirations/education" Icon={<Pointer tw="w-12" />}>
          Ensure access to education and reduce barriers for all
        </IconCard>
        <IconCard
          variant={VARIANT.ROSE}
          to="/aspirations/workforce-development"
          Icon={<Bag tw="w-12" />}
        >
          Lead in workforce development
        </IconCard>
      </div>
    </div>
  </Layout>
);

export default ClimatePage;
